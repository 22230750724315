// export const BASE_AUTH = 'https://api.maira.myrepublic.net.id/';
// export const BASE_URL = 'https://api.maira.myrepublic.net.id/';
// export const BASE_AUTH = 'http://localhost:9000/';
// export const BASE_URL = 'http://localhost:9000/';
// export const BASE_AUTH = 'http://myrepublic-customer-api.barrans.id/';
// export const BASE_URL = 'http://myrepublic-customer-api.barrans.id/';
// export const BASE_AUTH = 'https://myrepublic-api.barrans.id';
// export const BASE_URL = 'https://myrepublic-api.barrans.id';
export const BASE_AUTH = 'https://myrepublic-api-prod-test.barrans.id';
export const BASE_URL = 'https://myrepublic-api-prod-test.barrans.id';
export const RESPONSE_STATUS = {
  SUCCESS: 200,
  NEED_ACTION: 300,
  UNAUTHORIZED: 401,
  BAD_REQUEST: 400,
  FORBIDDEN: 403,
  ERROR: 500,
  NOT_ALLOW: 405,
  NOT_FOUND: 404,
};
export const REACT_APP_VAPID_KEY =
  'BIJnY9Eun9JKqEyx-6zmuJPLmZGUfa_PEx3xDNvXOJQYmO5aTOuUiUayGyxTQV6t6FzPDtP8dJT085zTHJHF-Ho';
